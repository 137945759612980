import React, { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { HotTable } from "@handsontable/react";
import axios from 'axios';
import ReactJson from 'react-json-view';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';

const data = [
    ['Sample', 'amount'],
    ['titanium monoxide', 10],
    ['titanium dioxide', 20],
    ['titanium trioxide', 30],
    ['epoxy', 40],
    ['epon 828', 50],
    ['d400', 60],
    ['mixing time', 70],
    ['mixing speed', 80],
];

const DictationComponent = ({ xUser, noteSetter, qidSetter, ...props }) => {
    const [listening, setListening] = useState(false);
    const [NLPBody, setNLPBody] = useState({});
    const [userText, setUserText] = useState('');
    const [hotData, setHotData] = useState(data);

    function setVals(qid, noteBody) {
        console.log("Set vals called!");
        noteSetter(noteBody);
        qidSetter(qid);
    }

    const commands = [
        {
            command: 'insert * terminate qid insert * terminate note',
            callback: (qid, noteBody) => setVals(qid, noteBody)
        },
        {
            command: 'find by qid * finished',
            callback: (qid) => qidSetter(qid)
        },
        {
            command: 'set note * finish note',
            callback: (note) => noteSetter(note)
        },
        {
            command: 'finish',
            callback: () => parseTranscript()
        },
        {
            command: 'clear',
            callback: () => resetTranscript()
        },
    ]
    const { transcript, resetTranscript } = useSpeechRecognition({ commands });

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        return null
    }

    function parseTranscript(text) {
        const url = 'https://aichemy.rd.mi.cld.3m.com/api/v1/nlp/extract'
        let data = ''
        if (!text) {
            data = { 'input_str': transcript }
        }
        else {
            data = { 'input_str': text }
        }
        const config = { headers: { "X-User": xUser } }
        axios.post(url, data, config).then(resp => {
            const jsonBody = resp.data;
            setNLPBody(jsonBody)
            console.log('API')
            setNewValues(jsonBody['attribute_data'])
        });


    }

    const setNewValues = (attribute_data) => {
        let newHotData = hotData

        Object.keys(attribute_data).forEach(key => {
            const match = attribute_data[key]
            const matchPair = match['1']
            const sampleName = Object.keys(matchPair)[0]
            const sampleAmt = matchPair[sampleName]
            newHotData = newHotData.map( (row) => {
                if (row.includes(sampleName)) {
                    return [sampleName, sampleAmt]
                }
                else return row
            })

        })
        setHotData(newHotData)
    }

    function startListening() {
        console.log("Starting listening");
        SpeechRecognition.startListening({ continuous: true });
        setListening(!listening);
    }

    function stopListening() {
        console.log("Stopped Listening");
        SpeechRecognition.stopListening();
        setListening(!listening);
    }
    return (
        <Box>
            {listening ? <Button variant="contained" color="primary" onClick={() => { stopListening() }}>Stop</Button> : <Button variant="contained" color="primary" onClick={() => { startListening() }}>Start</Button>}
            <Button onClick={() => { resetTranscript() }} variant="contained" color="secondary" startIcon={<DeleteIcon />}>Reset</Button>
            <Typography variant="subtitle1">
                Transcript
            </Typography>
            <Typography>{transcript}</Typography>

            <Button onClick={() => parseTranscript(userText)} variant="contained" color="secondary" startIcon={<DeleteIcon />}>Parse Current Text</Button>
            <TextField value={userText} onChange={(event) => setUserText(event.target.value)} label="Test Text"/>
            <HotTable data={hotData} colHeaders={true} rowHeaders={true} width={600} height={300}/>
            {NLPBody ? <ResponseBodyView body={NLPBody} /> : null}
        </Box>
    )
}

const SampleNoteAdder = () => {
    const [xUser, setXUser] = useState("");
    const [qid, setQid] = useState("");
    const [sampleId, setSampleId] = useState("");
    const [noteBody, setNoteBody] = useState("");
    const [respBody, setRespBody] = useState({});

    // find sample ID on QID update
    useEffect(() => {
        // fix this eventually
        axios.get(`https://firecracker.qa.mi.cld.3m.com/api/v1/samples?filter=[{"name":"qid","op":"eq","val":"${qid}"}]`, { headers: { "X-User": xUser } }).then(resp => {
            const jsonBody = resp.data.data;
            if (jsonBody.length === 1) {
                setSampleId(jsonBody[0].id);
                setRespBody(jsonBody);
            }
            else {
                console.log(jsonBody.length);
            }
        });
    }, [qid, xUser]);

    function sendNotePatch() {
        console.log("SENDING");
    }

    return (<form noValidate authoComplete="off">
        <TextField value={xUser} label="Firecracker API Token" onChange={(event) => { setXUser(event.target.value) }} />

        <TextField value={qid} label="Sample QID" onChange={(event) => { setQid(event.target.value) }} />
        <label>
            Sample ID
            <p>{sampleId}</p>
        </label>

        <TextField value={noteBody} label="Note Body" onChange={(event) => { setNoteBody(event.target.value) }} />

        <DictationComponent xUser={xUser} noteSetter={setNoteBody} qidSetter={setQid} />
        <Button color="primary" onClick={() => { sendNotePatch() }}>Update Note</Button>
        {respBody ? <ResponseBodyView body={respBody} /> : null}
    </form>);

}

export default SampleNoteAdder;

const ResponseBodyView = ({ body, ...props }) => {
    // can do something more here if i want
    return (
        <ReactJson src={body} />
    );
}