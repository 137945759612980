export function getQid(host, header) {
    const QidUrl = host + '/qids/1';
    const requestOptions = {
        url: QidUrl,
        dataType: 'json',
        method: 'Get',
        headers: header,
    };
    return fetch(QidUrl, requestOptions)
        .then(res => res.ok ? res.json() : null)
        .then(data => data && data.data.qids[0]);
}

export function parseUserId(token) {
    let match = token.match(/^.*_([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i)
    return match ? match[1] : null
}
