import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Router } from "@reach/router";
import { decode } from 'url-encode-decode';

const TopLevel = (props) => {
  const headers = new Headers();
  headers.append("INSTRUMENT-API-TOKEN", props.token)
  return <App host={decode(props.host)} header={headers} id={props.id} qid={props.qid ? decode(props.qid) : null}/>;
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <TopLevel path="/:id/:token/:host/" component={TopLevel} />
      <TopLevel path='/:id/:token/:host/:qid/' component={TopLevel} />
      <App path="/" />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);