import React, { useState, useEffect, Fragment } from 'react';
import './App.css';
import logo from './media/logo.png'
import gradient from './media/gradient_bar.9f3f0614.svg'
import MMM from './media/3M_logo.6cbbbb91.jpg'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import styles from './styles/dashboard_styles';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import HelpButton from './components/HelpButton';
import AuthAvatar from './components/AuthAvatar';
import { parseUserId } from './functions/utils';
import SampleNoteAdder from './components/SampleNoteAdder';

const useStyles = makeStyles(styles);

const App = (props) => {
    const [header, setHeader] = useState(props.header ? props.header : null);
    const [host, setHost] = useState(props.host ? props.host : null);
    const [name, setName] = useState(null);
    const [auth, setAuth] = useState(false);
    const [ip, setIp] = useState(null);
    const [userId, setUserId] = useState(null);

    const classes = useStyles();

    const checkAuth = () => {
        if (!header) {
            setAuth(false)
        } else {
            if (header) {
                const echo_url = `${host}/auth_echo/labcam`
                const requestOptions = {
                    url: echo_url,
                    dataType: 'json',
                    method: 'Get',
                    headers: header,
                };
                fetch(echo_url, requestOptions)
                    .then(response => {
                        switch (response.status) {
                            case 200:
                                // console.log('API access granted', response)
                                setAuth(true)
                                response.json().then(data => setIp(data.data.attributes.ip))
                                // try to set the user ID from the token
                                if (header.get("INSTRUMENT-API-TOKEN")) {
                                    setUserId(parseUserId(header.get("INSTRUMENT-API-TOKEN")))
                                }

                                // console.log("at ip fingerprint", ip)

                                break
                            case 401:
                                console.log('Not authorized')
                                setHeader(null)
                                setHost(null)
                                setAuth(false)
                                break
                            default:
                                setHeader(null)
                                setHost(null)
                                setAuth(false)
                        }
                    }
                    )
            }
        }
    }

    return (
        <div>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <img src={logo} height="25" alt="LabCam" />
                    <img src={MMM} height="25" alt="logo" style={{ margin: "auto" }} />
                    <HelpButton />
                    <AuthAvatar
                        name={name}
                        auth={auth}
                        checkAuth={checkAuth}
                        setAuth={setAuth}
                        setHeader={setHeader}
                        setName={setName} />

                </Toolbar>
                <img src={gradient} height="5" width="100%" alt="gradient" />
            </AppBar>
            <Paper className={classes.paper} >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SampleNoteAdder />
                    </Grid>

                </Grid>
            </Paper>
        </div>
    );
}
export default App
