import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Help from '@material-ui/icons/Help';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Closed from '@material-ui/icons/ArrowLeft'
import Opened from '@material-ui/icons/ArrowDropDown'

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    text: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}));


export const HelpButton = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [expandHowTo, setExpandHowTo] = useState(false);
    const [expandFAQS, setExpandFAQS] = useState(false)

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const handleExpandHowTo = () => {
        setExpandHowTo(!expandHowTo);
    };

    const handleExpandFAQS = () => {
        setExpandFAQS(!expandFAQS);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <div>
            <IconButton onClick={handleClick} >
                <Help />
            </IconButton>
            <Popover id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List className={classes.container}>
                    <ListItem button onClick={handleExpandHowTo}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="How to Use LabVoice" />
                        {expandHowTo ? <Closed /> : <Opened />}
                    </ListItem>
                    <Collapse in={expandHowTo}>
                        <List component="div">
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText className={classes.text} primary={
                                    <Typography variant={'body1'}>
                                        1 -Tell Someone to update the docs <br />
                                    </Typography>} />
                            </ListItem>
                        </List>
                    </Collapse>
                </List>

                <List className={classes.container}>
                    <ListItem button onClick={handleExpandFAQS}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="FAQS" />
                        {expandFAQS ? <Closed /> : <Opened />}
                    </ListItem>
                    <Collapse in={expandFAQS}>
                        <List component="div">
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText className={classes.text} primary={
                                    <div>
                                    </div>
                                } />
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Popover>
        </div>
    );
}

export default HelpButton