import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
const StyledBadge = withStyles((theme) => ({
    badge: {
        // backgroundColor: props.auth ? 'lightgreen' : 'red',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    }
}))(Badge);

const makeInitials = (name) => {
    if (name) {
        var initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    } else {
        return '';
    }
}

export const AuthAvatar = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [render, setRender] = useState(false);
 
    //change render state every 30 seconds
    useEffect(() => {
        setTimeout(() => {
            setRender(!render);
        }, 30000);
    }, [render]);

    //check auth state every 30 seconds
    useEffect(() => { props.checkAuth() }, [props.name, render]);

    function handleClose() {
        setAnchorEl(null);
    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const doLogout = (event) => {
        props.setAuth(false)
        props.setHeader(null)
        props.setName(null)
    }

    return (
        <div>
            <IconButton 
                onClick={handleMenu}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true">
                <StyledBadge
                    color={props.auth ? "primary" : "error"}
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    variant="dot">
                    <Avatar>{makeInitials(props.name)}</Avatar>
                </StyledBadge>
            </IconButton>
            {props.auth &&
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={doLogout}>Logout</MenuItem>
            </Menu>}

        </div>
    );
}






export default AuthAvatar
